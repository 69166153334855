module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/data/messages","languages":["fr","en"],"defaultLanguage":"fr","redirect":false,"redirectDefaultLanguageToRoot":false,"ignoredPaths":["/**"],"fallbackLanguage":"fr"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"config/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gîtes de Kervenen : location de gîte en Bretagne à la Forêt-Fouesnant","short_name":"Kervenen","description":"Location de gîte en Bretagne, en bord de mer, à la Foret-Fouesnant. Les gîtes de Kervenen sont référencés 3 étoiles chez Gîtes de France. Gîtes de Kervenen, les gîtes parfaits où passer vos vacances en Bretagne.","start_url":"/","background_color":"#064fa5","theme_color":"#ffffff","display":"standalone","icon":"src/images/icons/android-icon-192x192.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"615e684238ae069406b033584bdd0085"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
